<script>
import Vue, { ref, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import { formatDollar } from '/~/utils/format/money'
import BaseAlert from '/~/components/base/alert/base-alert.vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useAddresses } from '/~/composables/addresses'
import { useEwallet } from '/~/composables/ewallet'
import {
  usePaymentMethods,
  useVerifyCard,
} from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useQrPay } from '/~/composables/use-qr-pay'
import ScanQr from './scan-qr.vue'

const getEmptyForm = (qrData) => {
  return {
    to: qrData,
    from: null,
    amount: qrData ? parseFloat(qrData.merchantCurrencyAmount) : 0,
    fees: 0,
    amountWithFee: 0,
    reference: '',
    cvv: '',
    qrData,
  }
}

export default {
  name: 'scan-pay-qr',
  components: {
    BaseAlert,
    BaseButton,
    BaseAsidePage,
    ScanQr,
  },
  setup() {
    const router = useRouter()
    const {
      qrPayConfirm,
      getPayFee,
      qrEwalletPayCheckout,
      getQrPayOrder,
      qrPayFail,
    } = useQrPay()
    const { fetchPaymentMethods } = usePaymentMethods()
    const { verifyCard } = useVerifyCard()
    const { defaultAddress } = useAddresses()
    const { ewalletBalance } = useEwallet()

    const form = ref(getEmptyForm())
    const errorMessage = ref('')
    const securityToken = ref('')
    const isProcessing = ref(false)
    const isCancelling = ref(false)
    const isFeesLoading = ref(false)

    const isSubmitDisabled = computed(() => {
      return (
        Boolean(errorMessage.value) ||
        isFeesLoading.value ||
        isProcessing.value ||
        isCancelling.value ||
        !form.value.qrData ||
        !form.value.from
      )
    })

    function onPaymentMethodChanged() {
      const { from, amount } = form.value

      errorMessage.value = ''
      form.value.cvv = ''

      if (
        from &&
        from.type === PaymentMethodType.eWallet &&
        ewalletBalance.value < amount
      ) {
        errorMessage.value = 'Insufficient funds'
      } else {
        getFees()
      }
    }

    function reset() {
      form.value = getEmptyForm()
      isProcessing.value = false
      isCancelling.value = false
    }

    async function getFees() {
      const { from, amount, qrData } = form.value

      isFeesLoading.value = true

      try {
        const response = await getPayFee({
          paymentSources: [
            {
              paymentMethodId: from.id,
              amount: String(amount),
            },
          ],
          transactionId: qrData.ewmpTransaction?.id,
        })

        form.value.amountWithFee = parseFloat(response.data?.total || 0)
        form.value.fees = parseFloat(response.data?.fees || 0)
      } catch (error) {
        onPayFail(error?.data?.message || 'Failed to load fees')
      } finally {
        isFeesLoading.value = false
      }
    }

    async function onPayFail(errorMessage) {
      const { amount, qrData } = form.value

      try {
        await qrPayFail({
          customerCurrencyAmount: String(amount),
          merchantCurrencyAmount: qrData.merchantCurrencyAmount,
          merchantTemporaryTransactionId: qrData.merchantTemporaryTransactionId,
        })

        emitter.emit('payment:failed', errorMessage)
        router.push({
          hash: '#profile-scan',
        })
      } catch (error) {
        console.error(error)
        Vue.notify({
          text: 'Something went wrong while uploading. Please try again.',
          type: 'error',
          duration: 5000,
        })
      }
    }

    function redirectToNewAddress() {
      router.push({
        hash: '#profile-add-address',
      })
    }

    async function onPaySubmit() {
      const { from } = form.value
      const isCard = from.type === PaymentMethodType.creditCard

      isProcessing.value = true

      try {
        if (isCard) {
          if (!defaultAddress.value) {
            redirectToNewAddress()
            return
          }

          const { securityToken: newSecurityToken, cvv } = await verifyCard({
            card: from,
            amount: form.value.amountWithFee,
            subTotal: form.value.amount,
            address: defaultAddress.value,
            modalsConfig: {
              mobile: true,
              to: 'menu-modal',
            },
          })

          securityToken.value = newSecurityToken
          form.value.cvv = cvv
        }
        makeTransfer()
      } catch (error) {
        Vue.notify({
          text: error,
          type: 'error',
          duration: 5000,
        })
        isProcessing.value = false
      }
    }

    async function onPayCancel() {
      const { qrData, amountWithFee, amount } = form.value

      isCancelling.value = true

      try {
        if (!qrData) {
          throw new Error()
        }

        emitter.emit('payment:cancel')
        await qrPayConfirm({
          status: 'CANCELLED',
          customerCurrencyAmount: String(amountWithFee || amount),
          merchantCurrencyAmount: qrData.merchantCurrencyAmount,
          merchantTemporaryTransactionId: qrData.merchantTemporaryTransactionId,
          technicalSwitchTransactionId: null,
        })
        Vue.notify({
          text: 'Transaction cancelled by the user',
          type: 'warning',
          duration: 5000,
        })
      } catch (error) {
        console.error(error)
        Vue.notify({
          text: 'Something went wrong while uploading. Please try again.',
          type: 'error',
          duration: 5000,
        })
      } finally {
        router.push({
          hash: '#profile-home',
        })
        isCancelling.value = false
      }
    }

    async function makeTransfer() {
      const { qrData, from, amountWithFee, amount, to, cvv } = form.value

      isProcessing.value = true

      try {
        const order = await qrEwalletPayCheckout({
          paymentSources: [
            {
              paymentMethodId: from.id,
              amount: String(amountWithFee || amount),
              securityToken: securityToken.value,
              verificationCode: String(cvv),
            },
          ],
          transactionId: qrData.ewmpTransaction?.id,
        })

        const response = await qrPayConfirm({
          customerCurrencyAmount: String(amountWithFee || amount),
          customerWalletId: from.reference || from.id,
          customerWalletTransactionId: order.data.number,
          merchantCurrencyAmount: qrData.merchantCurrencyAmount,
          merchantTemporaryTransactionId: qrData.merchantTemporaryTransactionId,
          technicalSwitchTransactionId: null,
        })

        fetchPaymentMethods()
        emitter.emit('activity:refresh')

        const qrPayOrder = await getQrPayOrder(order.data.number)

        router.push({
          hash: '#profile-pay-confirmation',
        })

        reset()
        nextTick(() => {
          emitter.emit('menu-pay-confirmation', {
            details: {
              payee: qrPayOrder.data.payee,
              payer: qrPayOrder.data.payer,
              to,
              from,
              number: response.number,
              createdAt: new Date(),
              status: response.status === 'OK' && 'completed',
              amount: qrData.merchantCurrencyAmount,
            },
            type: 'pay-to',
            backRoute: { hash: '#profile-home' },
          })
        })
      } catch (error) {
        onPayFail(
          error?.data?.message ||
            error?.message ||
            error?.title ||
            'Something went wrong. Please, try again.'
        )
        isProcessing.value = false
      }
    }

    function setFormFrom(from) {
      form.value.from = from
    }

    return {
      form,
      isSubmitDisabled,
      isFeesLoading,
      isProcessing,
      isCancelling,
      errorMessage,
      onPaymentMethodChanged,
      onPaySubmit,
      formatDollar,
      onPayCancel,
      setFormFrom,
    }
  },
  computed: {
    qrData() {
      return this.$route.params.qrData
    },
  },
  watch: {
    'form.from'() {
      if (this.form.to) {
        this.onPaymentMethodChanged()
      }
    },
  },
  created() {
    if (this.qrData) {
      this.form = getEmptyForm(this.qrData)
      emitter.on('menu:pay-anyone:select-from', this.setFormFrom)
    }
  },
  beforeDestroy() {
    emitter.off('menu:pay-anyone:select-from', this.setFormFrom)
  },
}
</script>

<template>
  <base-aside-page title="Pay QR" :back="{ hash: '#profile-home' }">
    <div class="flex h-full flex-col items-center justify-between">
      <scan-qr
        :form="form"
        :submitting="isProcessing"
        :is-fees-loading="isFeesLoading"
        @amount="form.amount = $event"
        @reference="form.reference = $event"
      />

      <base-alert v-if="errorMessage" type="warning" class="flex-shrink-0">
        <span class="ml-2.5 font-bold">
          {{ errorMessage }}
        </span>
      </base-alert>

      <div v-if="form.fees" class="mt-5 flex w-full justify-between">
        <span>Fees</span>
        <span>{{ formatDollar(form.fees) }}</span>
      </div>
      <div class="mt-5 flex w-full justify-between">
        <base-button
          :loading="isCancelling"
          :disabled="isProcessing || isCancelling"
          look="outlined-color"
          class="w-[174px]"
          @click="onPayCancel"
        >
          Cancel
        </base-button>

        <base-button
          :loading="isProcessing || isFeesLoading"
          :disabled="isSubmitDisabled"
          type="primary"
          class="w-[174px]"
          @click="onPaySubmit"
        >
          Pay
        </base-button>
      </div>
    </div>
  </base-aside-page>
</template>
