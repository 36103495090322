<script>
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerPaymentFrom from '/~/components/drawer/components/rows/drawer-payment-from.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useQrPay } from '/~/composables/use-qr-pay'

export default {
  name: 'scan-qr',
  components: {
    DrawerPaymentFrom,
    BaseInput,
    BaseIcon,
    BaseLoader,
    BaseCurrencyInput,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    qrOnlyMode: {
      type: Boolean,
      default: false,
    },
    isFeesLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { qrPayBegin } = useQrPay()
    const { isDarkThemeForEwallet } = useCms()

    return {
      isDarkThemeForEwallet,
      qrPayBegin,
    }
  },
  data() {
    return {
      readerActivated: false,
      qrDataLoading: false,
    }
  },
  computed: {
    qrItemClickHandler() {
      if (this.form.qrData && !this.submitting) {
        return 'click'
      }

      return null
    },
    qrMerchantName() {
      const { qrData } = this.form

      return qrData?.merchantDetail?.merchantName || ''
    },
  },
  methods: {
    async onQRInput(qrString) {
      const { form } = this

      if (form.to) {
        return
      }

      form.to = { id: qrString }

      this.qrDataLoading = true

      const [error, qrData] = await this.qrPayBegin(qrString)

      if (error) {
        if (this.qrOnlyMode) {
          this.$router.replace({ hash: '#profile-scan' })
        } else {
          this.rescan()
        }
      } else {
        form.qrData = qrData
        form.amount = parseFloat(qrData.merchantCurrencyAmount)
        form.reference = 'Espresso coffee cup'
      }

      this.qrDataLoading = false
    },
    rescan() {
      const { form } = this

      form.to = null
      form.qrData = null

      this.readerActivated = true
    },
  },
}
</script>

<template>
  <div class="w-full">
    <div
      class="mb-2.5 text-sm font-bold"
      :class="{
        'text-eonx-neutral-600': !isDarkThemeForEwallet,
      }"
    >
      From
    </div>

    <router-link
      :to="{ hash: '#profile-scan-pay-from' }"
      class="mb-2.5 flex min-h-[72px] cursor-pointer items-center justify-between rounded-lg border bg-white px-[15px] py-2.5"
      :class="{
        'pointer-events-none opacity-80': submitting,
        'text-eonx-neutral-800': isDarkThemeForEwallet,
      }"
      :event="submitting ? '' : 'click'"
    >
      <drawer-payment-from v-if="form.from" :from="form.from">
        <template #action>
          <div v-show="!submitting">
            <base-icon svg="plain/chevronright" size="sm" />
          </div>
        </template>
      </drawer-payment-from>

      <div v-else class="flex w-full items-center">
        <div class="flex h-12 w-12 flex-shrink-0 items-center justify-center">
          <base-icon svg="v2/custom/credit-card-outline" :size="24" />
        </div>

        <div class="flex-1 truncate pl-[15px] font-bold">
          Select or add payment method
        </div>

        <base-icon svg="v2/custom/chevron-right-outline" :size="14" />
      </div>
    </router-link>

    <div
      v-if="form.qrData"
      :to="{ hash: '#profile-scan-pay-from' }"
      class="mb-[30px] flex min-h-[72px] cursor-pointer items-center justify-between rounded-lg border bg-white px-[15px] py-2.5"
      :class="{
        'cursor-default': submitting,
        'text-eonx-neutral-800': isDarkThemeForEwallet,
      }"
    >
      <div class="flex w-full items-center">
        <div
          class="mr-[15px] flex h-12 w-12 flex-shrink-0 items-center justify-center"
        >
          <base-loader v-if="qrDataLoading" size="xs" />
          <div
            v-else
            class="flex h-full w-full flex-shrink-0 items-center justify-center rounded-full bg-emerald-700 text-white"
          >
            <base-icon svg="v2/heroic/check" :size="24" />
          </div>
        </div>

        <div class="flex-1 overflow-hidden">
          <div class="truncate font-bold">
            <span v-if="qrMerchantName">
              {{ qrMerchantName }}
            </span>
            <span v-else>QR code</span>
          </div>
          <div
            v-show="qrMerchantName"
            class="truncate text-sm text-eonx-neutral-600"
          >
            QR code
          </div>
        </div>
      </div>
    </div>

    <div
      class="mb-2.5 text-sm font-bold"
      :class="{
        'text-eonx-neutral-600': !isDarkThemeForEwallet,
      }"
    >
      Amount
    </div>

    <div class="flex h-12 items-center rounded-md border px-4">
      <base-currency-input
        :value="form.amount"
        :allow-negative="false"
        nolabel
        data-vv-as="Amount"
        name="amount"
        class="w-full font-bold"
        :style="{
          '--color-text': 'var(--color-gray-600)',
        }"
        :disabled="submitting"
        :readonly="true"
        @input="$emit('amount', $event)"
      />
    </div>

    <div
      class="mb-2.5 mt-[30px] text-sm font-bold"
      :class="{
        'text-eonx-neutral-600': !isDarkThemeForEwallet,
      }"
    >
      Reference or description
    </div>

    <base-input
      :value="form.reference"
      :disabled="submitting"
      nolabel
      data-vv-as="Reference or description"
      name="reference"
      class="font-bold"
      :style="{
        '--color-text': 'var(--color-gray-600)',
      }"
      entry-class="h-12 rounded-md"
      @input="$emit('reference', $event)"
    />
  </div>
</template>
